@media (max-width: 1024px) {

   .deal_slide_btn.swiper-button-next {
      right: 10px;
   }

   .deal_slide_btn.swiper-button-prev {
      left: 10px;
   }
}

@media (max-width: 992px) {
	.contact_main{
		flex-direction: column-reverse;
	}
   .navigation {
      position: fixed;
      height: 100%;
      width: 300px;
      background: var(--primary);
      z-index: 111;
      flex-direction: column;
      justify-content: flex-start;
      grid-gap: 0;
      right: -300px;
      top: 0;
      transition: 0.5s;
      grid-gap: 10px;
   }

   .menu_btn.close {
      width: 100%;
      padding: 0 15px;
      justify-content: flex-start;
   }

   .menu_btn.close svg {
      height: 25px;
      width: 25px;
   }

   .navigation.active {
      right: 0;
   }

   .navigation a {
      padding: 10px 20px;
      border-width: 2px;
   }

   nav {
      position: absolute;
   }

   .menu_btn {
      display: flex;
   }


   .header_top_main>* {
      flex: unset;
   }

   .header_btn_wrapper {
      margin-left: auto;
   }

   .header_contact_main a {
      display: none;
   }

   .header_contact_flex,
   .header_contact_main {
      justify-content: center;
      text-align: center;
   }
}

@media (max-width: 768px) {
   .deal_slide {
      height: 300px;
   }

   .header_contact_main {
      display: none;
   }
}

@media (max-width: 500px) {
   .menu_btn:not(.close) {
      order: 2;
      justify-content: flex-end;
   }

   .menu_btn.close {
      justify-content: flex-start;
   }

   .deal_slide_btn {
      display: none;
   }

   .deal_slide_content_main {
      padding: 20px;
   }

   .header_contact p svg {
      display: none;
   }

   .header_btn_wrapper {
      display: none;
   }

   .logo {
      justify-content: center;
   }

   .header_top_main {
      justify-content: center;
   }

   .header_top_main>* {
      flex: 1;
   }
}